
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IOrder } from "@/types/order";
import { ISupplierStaffListRequest } from "@/types/supplier_staff";
import moment from "moment";
import {
  IInvoice,
  IInvoiceCalculation,
  IInvoiceListRequest
} from "@/types/invoice";
import {
  IInvoiceDuplicateProduct,
  IInvoicePrintRequest
} from "@/types/ship_slip";
import { IShipment } from "@/types/shipment";

@Component({
  components: {}
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // invoice
  @Action("invoice/adminGet")
  public getInvocie!: (id: number) => void;

  @Action("invoice/adminInvoiceCalculation")
  public getInvoiceCalculation!: (request: IInvoiceListRequest) => void;

  @Action("invoice/adminIsPrinted")
  public isPrinted!: (data: {
    invoice_id: number;
    requestIsPrinted: { is_printed: boolean };
  }) => boolean;

  @Getter("invoice/single")
  public invoice!: IInvoice;

  @Getter("invoice/invoice_calculation")
  public invoiceCalculationResult!: IInvoiceCalculation;

  @Mutation("invoice/clear")
  public clearInvoice!: () => void;

  // duplicate_check
  @Action("ship_slip/adminInvoiceDuplicateCheck")
  public duplicateCheck!: (id: number) => void;

  @Getter("ship_slip/invoiceDuplicateProduct")
  public duplicate_product!: IInvoiceDuplicateProduct[];

  // invoice and slip
  @Action("pdf/adminPrintInvoiceSlipA5")
  public printInvoiceSlip!: (data: {
    invoice_id: number;
    params: IInvoicePrintRequest;
  }) => boolean;

  @Action("pdf/adminPrintInvoiceSlipA5")
  public printInvoiceSlipA5!: (data: {
    invoice_id: number;
    invoice_no: string;
    customer_name: string;
    invoice_date: string;
    params: IInvoicePrintRequest;
  }) => boolean;

  @Action("pdf/adminPrintInvoiceSlipA4")
  public printInvoiceSlipA4!: (data: {
    invoice_id: number;
    invoice_no: string;
    customer_name: string;
    invoice_date: string;
    params: IInvoicePrintRequest;
  }) => boolean;

  // パラメータ定義
  public params: IInvoiceListRequest = {
    invoice_id: 0
  };

  // fields
  public invoice_id = 0;
  public supplier_id = 0;
  public supplierStaffParams: ISupplierStaffListRequest = {};
  public supplier_staffs = {};

  public async created() {
    this.invoice_id = Number(this.$route.params.invoice_id);
    this.params.invoice_id = this.invoice_id;
    this.clearInvoice();

    await Promise.all([
      this.getInvocie(this.invoice_id),
      this.getInvoiceCalculation(this.params)
    ]);
  }

  public postalcode_insert(val: string) {
    return val ? "〒" + val.slice(0, 3) + "-" + val.slice(3, val.length) : "";
  }

  public tax_calc(val: IOrder) {
    if (!val.tax_rate) {
      return;
    }
    return (val.subtotal * (val.tax_rate / 100)).toFixed(2).toLocaleString();
  }

  public shipment_headers = [
    {
      text: "顧客注文番号",
      value: "received_order_no",
      sortable: false
    },
    {
      text: "伝票番号",
      value: "shipment_no",
      sortable: false
    },
    {
      text: "出荷日",
      value: "shipment_date",
      sortable: false
    },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },
    { text: "ロットNo.", value: "lot_no", sortable: false },
    {
      text: "数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },

    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    }
  ];

  public invoice_product_headers = [
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },
    { text: "請求商品メモ", value: "memo", sortable: false },

    {
      text: "数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    }
  ];

  get setShipmentData() {
    return this.invoice.shipment.map(el => ({
      received_order_id: el.received_order_id,
      received_order_no: el.received_order_no,
      shipment_date: this.omitAD(el.shipment_date),
      fixed_delivery_date: el.fixed_delivery_date,
      shipment_status: el.shipment_status,
      product_shipment: el.product_shipment,
      shipment_no: el.shipment_no
    }));
  }

  get setInvoiceProductData() {
    return this.invoice.invoice_product.map(el => ({
      product_name: el.product_name,
      product_code: el.product_code,
      customer_pn: el.customer_pn,
      quantity: Number(el.quantity),
      buying_unit_price: Number(el.buying_unit_price),
      selling_unit_price: Number(el.selling_unit_price),
      memo: el.memo
    }));
  }

  // subtotal
  public subtotal() {
    const expense = this.invoice.invoice_price_tag.reduce(function (
      previous,
      current
    ) {
      if (current.is_minus == true) {
        return current.price ? previous - current.price : previous;
      }
      return current.price ? previous + current.price : previous;
    },
    0);
    return this.invoice.sell_subtotal + expense;
  }

  // tax amount calc
  public taxAmount() {
    return Number((this.subtotal() * (this.invoice.tax_rate / 100)).toFixed(2));
  }

  //対象出荷データの行をクリックした際の挙動
  public rowClick(setShipmentData: IShipment) {
    this.$router.push(
      `/admin/received_order/${setShipmentData.received_order_id}`
    );
  }

  // これ以下は納品書関係
  public dialog = false;
  public dialog_show = false;
  public valid = true;
  public sheet_size_check = true;
  public address_list: string[] = [];
  public row_length = 0;
  public print_params: IInvoicePrintRequest = {
    shipment_id: 0,
    is_amount: false,
    is_amount_slip: false,
    sheet_size: "A5",
    sheet_type: "",
    is_company_stamp: true,
    print_date: "",
    delivery_slip_remark: "",
    invoice_remark: "",
    received_order_no: "",
    delivery_date: "",
    destination: "",
    products: []
  };
  public requestIsPrinted = { is_printed: false };

  //ルール設定
  public rules = {
    description_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 30 || "30文字以内で入力してください"
    ],
    destination_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 96 || "96文字以内で入力してください"
    ],
    order_no_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 72 || "72文字以内で入力してください"
    ],
    delivery_date_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 40 || "40文字以内で入力してください"
    ],
    remark_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 174 || "174文字以内で入力してください"
    ],
    product_limit_length: [
      (value: string) =>
        this.inputCounter(value) <= 60 || "60文字以内で入力してください"
    ]
  };

  // 印刷ボタン押下時の挙動
  public async print_set() {
    this.dialog = true;
    this.dialog_show = true;
    this.print_params.products.splice(0);
    this.address_list.splice(0);

    if (this.invoice.shipment.length > 0) {
      // use shipment data
      await this.duplicateCheck(this.invoice_id);

      this.duplicate_product.forEach(order_no_row => {
        order_no_row.products.forEach((product, product_key) => {
          product.sellings.forEach((el, key) => {
            this.print_params.products.push({
              product_name: product.customer_pn
                ? product.product_name +
                  " / " +
                  product.product_code +
                  " / " +
                  product.customer_pn
                : product.product_name + " / " + product.product_code,
              product_id: product.product_id,
              product_unit: product.product_unit,
              quantity_sum: el.quantity_sum,
              selling_unit_price: Number(
                Number(el.selling_unit_price).toFixed(2)
              ),
              is_tag: false,
              is_print: true,
              description:
                this.duplicate_product.length > 1 &&
                product_key == 0 &&
                key == 0
                  ? order_no_row.received_order_no
                  : "",
              product_rows: []
            });
          });
        });
      });

      // input destination
      const destinations: string[] = [];
      this.invoice.shipment.forEach(shipment => {
        destinations.push(
          shipment.received_order.destination
            ? shipment.received_order.destination
            : "上記住所"
        );
      });
      const duplicate_destinations: string[] = Array.from(
        new Set(destinations)
      );
      if (duplicate_destinations.length == 1) {
        this.print_params.destination = duplicate_destinations[0];
      } else {
        this.address_list = duplicate_destinations;
      }

      const order_nos: string[] = [];
      this.duplicate_product.forEach(element => {
        order_nos.push(element.received_order_no);
        this.print_params.received_order_no = order_nos.join("/");
      });

      // input delivery_date
      const delivery_dates: string[] = [];
      this.invoice.shipment.forEach(shipment => {
        delivery_dates.push(
          shipment.received_order.fixed_delivery_date
            ? this.dateFormatJP(shipment.received_order.fixed_delivery_date)
            : ""
        );
      });
      const duplicate_delivery_dates: string[] = Array.from(
        new Set(delivery_dates)
      );
      this.print_params.delivery_date = duplicate_delivery_dates.join("/");
    } else {
      // use invoice_product data
      this.invoice.invoice_product.forEach(item => {
        this.print_params.products.push({
          product_name: item.customer_pn
            ? item.product_name +
              " / " +
              item.product_code +
              " / " +
              item.customer_pn
            : item.product_name + " / " + item.product_code,
          product_id: item.product_id,
          product_unit: item.product_unit ? item.product_unit : "",
          quantity_sum: item.quantity ? item.quantity : 0,
          is_tag: false,
          is_print: true,
          selling_unit_price: Number(
            Number(item.selling_unit_price).toFixed(2)
          ),
          description: "",
          product_rows: []
        });
      });
    }
    // 値札
    this.invoice.invoice_price_tag.forEach(item => {
      this.print_params.products.push({
        product_name: item.tag_name,
        product_id: 0,
        product_unit: "",
        quantity_sum: 1,
        selling_unit_price: item.price ? item.price : 0,
        description: "",
        is_tag: true,
        is_print: true,
        product_rows: []
      });
    });

    this.sheet_size_check = true;
    this.valid = true;

    this.rowCount();

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  // 印刷の行数カウント
  public rowCount() {
    let length_sum = 0;
    length_sum += this.print_params.products.length;
    this.print_params.products.forEach(product => {
      length_sum += product.product_rows.length;
    });
    this.row_length = length_sum;

    if (length_sum > 6) {
      this.print_params.sheet_size = "A4";
      this.sheet_size_check = false;
    } else {
      this.sheet_size_check = true;
    }
  }

  // 印刷ダイアログの閉じる
  public print_close() {
    this.dialog = false;
    this.dialog_show = false;
    this.print_params.products.splice(0);
    this.sheet_size_check = true;
    this.valid = true;
  }

  // 印刷ダイアログの印刷
  public async getPrint() {
    // 印刷成功チェック
    let result = false;
    // 発行日の再指定
    const fixed_invoice_date = moment(this.invoice.invoice_date).format(
      "YYMMDD"
    );
    // 納品書金額ありなし
    this.print_params.is_amount = this.print_params.is_amount_slip;

    // A5サイズ
    if (this.print_params.sheet_size == "A5") {
      result = await this.printInvoiceSlipA5({
        invoice_id: this.invoice.id,
        params: this.print_params,
        invoice_no: this.invoice.invoice_no,
        customer_name: this.invoice.customer.name,
        invoice_date: fixed_invoice_date
      });
    }

    // A4サイズ
    // 納品書印刷
    if (this.print_params.sheet_size != "A5") {
      this.print_params.sheet_type = "slip";
      result = await this.printInvoiceSlipA4({
        invoice_id: this.invoice.id,
        params: this.print_params,
        invoice_no: this.invoice.invoice_no,
        customer_name: this.invoice.customer.name,
        invoice_date: fixed_invoice_date
      });

      // 請求書印刷
      if (result) {
        this.print_params.sheet_type = "invoice";
        this.print_params.is_amount = true;
        result = await this.printInvoiceSlipA4({
          invoice_id: this.invoice.id,
          params: this.print_params,
          invoice_no: this.invoice.invoice_no,
          customer_name: this.invoice.customer.name,
          invoice_date: fixed_invoice_date
        });
      }
    }

    if (result) {
      this.print_close();
    }

    if (result) {
      result = await this.isPrinted({
        invoice_id: this.invoice.id,
        requestIsPrinted: { is_printed: true }
      });
    }
  }

  // 印刷行を増やす
  public addRowBtn(index: number) {
    this.print_params.products[index].product_rows.push({
      product_name: "",
      description: "",
      is_print: true
    });
  }

  // 印刷行を減らす
  public deleteRowBtn(product_index: number, row_index: number) {
    this.print_params.products[product_index].product_rows.splice(row_index, 1);
  }
}
